<template>
    <div>
      <h3>作业量分布<span class="remark m-l-10">(单位：个)</span> </h3>
      <div style="width:100%;height:300px;" id="workMonthReport"></div>
    </div>
</template>

<script>
import { listWorkItemGroupByMonth } from '@/api/dw/maintainRole'
import * as echarts from 'echarts'

export default {
  data () {
    return ({
      workMonthData: [], // 作业月份数据
      dataSourcePie: null
    })
  },
  methods: {
    initPageData (searchModel) {
      listWorkItemGroupByMonth(searchModel).then(res => {
        this.workMonthData = res
        const xData = res.map(x => x.yearMonth)
        const yData = res.map(x => x.workNum)
        this.initReport(xData, yData)
      })
    },
    initReport (xData, yData) {
      if (this.dataSourcePie != null && this.dataSourcePie !== '' && this.dataSourcePie !== undefined) {
        this.dataSourcePie.dispose()
      }

      this.$nextTick(() => {
        this.dataSourcePie = echarts.init(document.getElementById('workMonthReport'))

        const option = {
          color: ['#2b85e4'],
          xAxis: {
            type: 'category',
            data: xData,
            axisLabel: {
              color: '#c5c8ce'
            }
          },
          yAxis: {
            type: 'value'
          },
          grid: {
            top: '10%',
            left: '5%',
            right: '5%',
            bottom: '20%',
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              crossStyle: {
                color: '#999'
              }
            }
          },
          series: [
            {
              data: yData,
              type: 'bar'
            }
          ]
        }

        this.dataSourcePie.setOption(option, true)
        const that = this
        window.addEventListener('resize', function () {
          that.dataSourcePie.resize()
        })
      })
    }
  }
}
</script>
