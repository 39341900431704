import request from '@/utils/requestV2'
const qs = require('qs')

// 获取指定时间段内的作业金额,按照作业类型分组
export function listWorkItemAmountGroupByType (data) {
  return request({
    url: '/ooh-dw/v1/datav/maintainRole/listWorkItemAmountGroupByType',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取指定时间段内的作业任务数,按照月份分组
export function listWorkItemGroupByMonth (data) {
  return request({
    url: '/ooh-dw/v1/datav/maintainRole/listWorkItemGroupByMonth',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取指定时间段内的作业任务数,按照线路、站点分组
export function listWorkItemGroupByStation (data) {
  return request({
    url: '/ooh-dw/v1/datav/maintainRole/listWorkItemGroupByStation',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取时间段内的各类型运维的作业数量以及完成率统计,按照作业类型分组
export function listWorkItemGroupByType (data) {
  return request({
    url: '/ooh-dw/v1/datav/maintainRole/listWorkItemGroupByType',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取时间段内的各类型运维的作业数量以及完成率统计,按照作业小组进行分组
export function listWorkItemGroupByWorkGroup (data) {
  return request({
    url: '/ooh-dw/v1/datav/maintainRole/listWorkItemGroupByWorkGroup',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 根据站点id查询站点内设备id列表
export function listDeviceIdByStation (data) {
  return request({
    url: '/ooh-dw/v1/datav/maintainRole/listDeviceIdByStation',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 根据设备id查询作业任务信息
export function listWorkItemByDeviceId (data) {
  return request({
    url: '/ooh-dw/v1/datav/maintainRole/listWorkItemByDeviceId',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 根据站点id查询站点下没有设备id的任务
export function listNoDeviceWorkItemByStation (data) {
  return request({
    url: '/ooh-dw/v1/datav/maintainRole/listNoDeviceWorkItemByStation',
    method: 'post',
    data: qs.stringify(data)
  })
}
