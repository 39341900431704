import request from '@/utils/requestV2'
const qs = require('qs')

// 获取线路的设备类型
export function getDeviceModelList (data) {
  return request({
    url: '/ooh-product/v1/device/getdevicemodellist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取设备状态集合
export function getStatusList (data) {
  return request({
    url: '/ooh-product/v1/device/getstatuslist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 设备分页列表
export function getDevicePage (data) {
  return request({
    url: '/ooh-product/v1/device/getdevicepage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取设备状态统计
export function getStatusQuantity (data) {
  return request({
    url: '/ooh-product/v1/device/getstatusquantity',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 修改设备状态
export function updateStatus (data) {
  return request({
    url: '/ooh-product/v1/device/updatestatus',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取设备历史状态记录
export function getHistoryList (data) {
  return request({
    url: '/ooh-product/v1/device/gethistorylist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取公司已设置的线路的devicemodel集合
export function exportDevice (data) {
  return request({
    url: '/ooh-product/v1/device/exportdevice',
    method: 'get',
    params: data
  })
}
// 根据当前租户和公司获取设备类型获取资产
export function getPublisherCompanyAssetList (data) {
  return request({
    url: '/ooh-product/v1/device/getpublishercompanyassetlist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 批量获取设备信息
export function getDeviceListByProduct (data) {
  return request({
    url: '/ooh-product/v1/device/getdevicelist',
    method: 'post',
    data: qs.stringify(data)
  })
}
