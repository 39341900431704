<template>
    <div>
        <!-- 供应商的运维态势 -->
        <SvgLine ref="svgline" @map-finished="handleMapLoadFinished" @on-click-station="handleClickStation"/>

        <SvgStation ref="svgStation"
          :stationSvgModal.sync="stationSvgModal"
          :stationId="chooseStationId"
          :svgShowType="6"
          :searchSchedules="searchSchedlue"
          @svg-finished="svgFinished"
          @on-select="handleSelectSvgResource"
          @handleShowOtherWorkItem="handleShowOtherWorkItem"
          @handleChange3dMode="handleBeginRevice3d"
        />

        <ThreeStation ref="threeStation" @three-finished="threeFinished" @handleChangeSvgMode="handleClickStation" />

        <Modal v-model="modelWorkItem" title="资源设备作业详情" width="1000" :footer-hide="true">
          <Table size="small" :columns="columns" :data="workItemData" stripe></Table>
        </Modal>

        <Modal v-model="visiblePreview" width="1200" :footer-hide="true">
            <p slot="header" class="text-center" >{{fileName}}</p>
            <img v-if="visiblePreview" :src="imageUrl+'?x-oss-process=image/resize,w_968'" style="width: 100%">
        </Modal>
    </div>
</template>

<script>
import SvgLine from '@/components/svg/SvgLine'
import SvgStation from '@/components/svg/SvgStation'
import ThreeStation from '@/components/svg/ThreeStation'

import { listWorkItemGroupByStation, listDeviceIdByStation, listWorkItemByDeviceId, listNoDeviceWorkItemByStation } from '@/api/dw/maintainRole'
import { getMaintenanceAssetList } from '@/api/rim/asset'

export default {
  components: {
    SvgLine, SvgStation, ThreeStation
  },
  data () {
    return {
      mapFinished: false,
      stationSvgModal: false, // 弹窗显示SVG站点地图
      chooseStationId: 0,
      currentSearchModel: null,

      modelWorkItem: false,
      columns: [
        { title: '作业类型', width: 100, key: 'typeName' },
        { title: '作业小组', width: 100, key: 'workGroupName' },
        { title: '作业时间', width: 100, key: 'actionDate' },
        { title: '完成时间', key: 'finishTime' },
        { title: '状态', width: 100, key: 'qualityTestingName' },
        { title: '作业名称', key: 'fileName' },
        {
          title: '作业画面',
          render: (h, params) => {
            if (params.row.fileKey === '') {
              return h('span', '-')
            } else {
              return h('img', {
                domProps: {
                  src: params.row.fileKey + '?x-oss-process=image/resize,w_64'
                },
                on: {
                  click: () => {
                    this.fileName = `${params.row.fileName}`
                    this.imageUrl = `${params.row.fileKey}`
                    this.visiblePreview = true
                  }
                },
                style: {
                  width: '64px',
                  cursor: 'pointer'
                }
              })
            }
          }
        }
      ],
      workItemData: [],

      visiblePreview: false,
      fileName: '',
      imageUrl: '',
      searchSchedlue: []
    }
  },
  methods: {
    initPageData (searchModel) {
      this.currentSearchModel = searchModel
      this.searchSchedlue = [{ startDate: searchModel.startDate, endDate: searchModel.endDate }]
      listWorkItemGroupByStation(searchModel).then(res => {
        const showStations = res.list.map(x => {
          return {
            id: x.stationId,
            quantity: x.workNum
          }
        })

        this.LoadMapData(showStations)
      })
    },
    handleMapLoadFinished (val) {
      this.mapFinished = val
      if (val) {
        getMaintenanceAssetList().then(res => {
          if (res && !res.errcode) {
            this.$refs.svgline.hideOtherAsset(res.map(x => x.id))
          }
        })
      }
    },
    LoadMapData (stations) {
      if (this.mapFinished) { // 验证地图是否加载完毕， 否则延迟1秒再次请求
        this.$refs.svgline.loadResourceStations(stations)
      } else {
        setTimeout(() => {
          this.LoadMapData(stations)
        }, 1000)
      }
    },
    handleClickStation (stationId) { // 地图点击站点事件
      this.chooseStationId = stationId
      this.stationSvgModal = true
    },
    svgFinished () { // SVG站点地图加载完毕
      const postData = Object.assign({}, this.currentSearchModel, { stationIds: this.chooseStationId })
      listDeviceIdByStation(postData).then(res => {
        if (res && !res.errcode) {
          this.$refs.svgStation.tagResource(res.map(x => {
            return {
              deviceId: x.deviceId,
              stationId: x.stationId,
              floor: x.floor
            }
          }))
          this.$refs.svgStation.choseSpinShow()
        }
      })
    },
    threeFinished () { // 3d场景加载完毕

    },
    handleSelectSvgResource (resourceId) {
      this.modelWorkItem = true
      this.workItemData = []
      const postData = Object.assign({}, this.currentSearchModel, { stationIds: this.chooseStationId, deviceId: resourceId })
      listWorkItemByDeviceId(postData).then(res => {
        this.workItemData = res
      })
    },
    handleShowOtherWorkItem () {
      this.modelWorkItem = true
      this.workItemData = []
      const postData = Object.assign({}, this.currentSearchModel, { stationIds: this.chooseStationId })
      listNoDeviceWorkItemByStation(postData).then(res => {
        this.workItemData = res
      })
    },
    handleBeginRevice3d (stationId) {
      this.$refs.threeStation.initData(stationId)
    }
  }
}
</script>
