<style scoped>
.svgContent {
  width: 100%;
  text-align: center
}
</style>

<template>
    <div v-html="svgHtml" id="svgWorkPlatform" class="svgContent"></div>
</template>

<script>
import svgPanZoom from 'svg-pan-zoom'
import { getCitys } from '@/api/product/asset'
import { getCitySvg } from '@/api/rim/asset'

import { getStorage } from '@/utils/storage'
import {
  generateResourceImageMarker, generateResourceTextMarker,
  generateFlickerImageMarker, clearMapMarker, checkStationidInList,
  checkStationIdByStringInList,
  checkTextPoint
} from '@/utils/mapCommon'

export default {
  props: {
    currentCategory: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      svgHtml: '',
      publisherId: this.$store.getters.token.userInfo.publisherId,
      assetColors: new Map(),
      currentMapObj: null,
      pathNodeList: [],
      gNodeList: []
    }
  },
  mounted () {
    this.initMap()
    // 设置中间区域地图高度
    this.$nextTick(() => {
      document.getElementById('svgWorkPlatform').style.height = document.body.clientHeight - 50 + 'px'
    })
  },
  methods: {
    initMap () {
      this.$emit('map-finished', false)
      getCitys({ publisherId: this.publisherId }).then(citys => {
        const categoryId = this.currentCategory === null ? null : this.currentCategory
        const data = { cityCode: citys[0].cityCode, categoryId: categoryId, publisherId: this.publisherId }

        getCitySvg(data).then(res => {
          this.svgHtml = res
          // 设置SVG地图的样式和缩放
          this.$nextTick(() => {
            if (!document.getElementById('svgWorkPlatform')) { return }
            // 设置svg呈现在画板上的尺寸
            document.getElementById('drawing').setAttribute('width', '100%')
            document.getElementById('drawing').setAttribute('height', '100%')

            this.currentMapObj = svgPanZoom('#drawing', {
              panEnabled: true,
              controlIconsEnabled: false,
              zoomEnabled: true,
              dblClickZoomEnabled: true,
              minZoom: 1,
              maxZoom: 5,
              center: true
            })

            if (this.publisherId === 12) { // 厦门地图不能放太大
              this.currentMapObj.zoom(1.5)
            } else {
              this.currentMapObj.zoom(2)
            }

            this.pathNodeList = document.getElementById('svgWorkPlatform').getElementsByTagName('path')
            this.gNodeList = document.getElementById('svgWorkPlatform').getElementsByTagName('g')

            if (this.pathNodeList.length > 0) {
              for (let i = 0; i < this.pathNodeList.length; i++) {
                const el = this.pathNodeList[i]
                if (el.hasAttribute('data-stationid')) {
                  if (el.nextSibling === null) {
                  // 移动SVG站台元素到容器最后方，属于SVG的z-index
                    el.parentElement.parentElement.appendChild(el.parentElement)
                  }

                  // 所有站点文字变为灰色
                  if (this.themeIsDark && el.nextSibling.nextElementSibling) {
                    el.nextSibling.nextElementSibling.children[0].setAttribute('fill', 'lightgrey')
                  }
                }

                // 替换大海颜色
                if (this.themeIsDark && el.hasAttribute('fill') && el.getAttribute('fill') === '#d5efff') {
                  el.setAttribute('fill', '#17233d')
                  el.setAttribute('stroke', '#17233d')
                }
              }
            }

            // 存储线路颜色
            this.saveAssetColor()
            this.$emit('map-finished', true)
          })
        })
      })
    },
    loadResourceStations (stations, type, warningStations) {
      clearMapMarker()
      // 遍历添加新的数据
      if (this.pathNodeList.length > 0) {
        let markerColor = 'blue'

        stations.forEach(element => {
          for (let i = 0; i < this.pathNodeList.length; i++) {
            const el = this.pathNodeList[i]
            // 站点ID，不同的传递
            const pathStationId = element.id ? element.id.toString() : element.stationId.toString()
            if (el.hasAttribute('data-stationid') && el.getAttribute('name') !== 'standardText' && checkStationidInList(el.getAttribute('data-stationid'), pathStationId)) {
              // 将图标气泡添加到当前站点上方
              if (el.nextSibling === null || el.nextSibling.nodeName === '#text') {
                // 库存页面显示无余量点位, 直接标注绿色，用于允许点击气泡查看SVG
                if (element.nothingQuantity) {
                  el.after(generateResourceImageMarker(this, el.getAttribute('data-stationid'), el.getAttribute('name'), 'green'))
                } else {
                  el.after(generateResourceTextMarker(this, element.quantity, el.getAttribute('data-stationid'), el.getAttribute('name')))
                  // 只有订单类型，并且当前站点在订单内，那么显示红色气泡。 默认是蓝色
                  if (type === 'order' && element.inOrder === 1) {
                    markerColor = 'red'
                  } else if (warningStations && warningStations.length > 0 && checkStationIdByStringInList(warningStations, el.getAttribute('data-stationid'))) {
                    markerColor = 'orange'
                  } else {
                    markerColor = 'blue'
                  }

                  el.after(generateResourceImageMarker(this, el.getAttribute('data-stationid'), el.getAttribute('name'), markerColor))
                }
              } else if (el.nextSibling.nextSibling.innerHTML) {
                // 在原有的数值上添加
                const stationQuantity = parseInt(el.nextSibling.nextSibling.innerHTML) + element.quantity
                if (stationQuantity > 99) {
                  el.nextSibling.nextSibling.setAttribute('x', checkTextPoint(el.getAttribute('name'))[0] - 8)
                } else if (stationQuantity > 9) {
                  el.nextSibling.nextSibling.setAttribute('x', checkTextPoint(el.getAttribute('name'))[0])
                } else { el.nextSibling.nextSibling.setAttribute('x', checkTextPoint(el.getAttribute('name'))[0] + 5) }
                el.nextSibling.nextSibling.innerHTML = stationQuantity
              }
            }
          }
        })
      }
    },
    loadFlickerStations (stationIds, addEvent = true) {
      clearMapMarker()
      if (stationIds.length === 0) { return }
      // 遍历添加新的数据
      for (let i = 0; i < this.pathNodeList.length; i++) {
        stationIds.forEach(element => {
          const el = this.pathNodeList[i]
          const pathStationId = element.toString()

          if (el.hasAttribute('data-stationid') && el.getAttribute('name') !== 'standardText' &&
             checkStationidInList(el.getAttribute('data-stationid'), pathStationId)) {
            el.after(generateFlickerImageMarker(addEvent ? this : null, el.getAttribute('data-stationid'), el.getAttribute('name')))
          }
        })
      }
    },
    handleClickStation (stationId) {
      this.$emit('on-click-station', stationId)
    },
    saveAssetColor () {
      this.assetColors = new Map()
      // 线条
      let gEl
      for (let i = 0; i < this.gNodeList.length; i++) {
        gEl = this.gNodeList[i]
        if (gEl.hasAttribute('data-assetid')) { // 当前节点包含线路ID
          if (!this.assetColors.get(gEl.getAttribute('data-assetid'))) {
            this.assetColors.set(gEl.getAttribute('data-assetid'), gEl.children[0].getAttribute('stroke'))
          }
        }
      }
    },
    hideOtherAsset (assetIds) {
      // 线路自动定位在最后一条上
      if (assetIds.length > 0) {
        this.handleMapGetCenterByLine(assetIds[assetIds.length - 1].toString())
      }

      // 线条
      let gEl
      let lineAssetId
      const assetHideColor = this.themeIsDark ? '#444' : '#E6E6E6'
      for (let i = 0; i < this.gNodeList.length; i++) {
        gEl = this.gNodeList[i]
        if (gEl.hasAttribute('data-assetid')) {
          // 隐藏线路，将线路颜色变为灰色
          lineAssetId = parseInt(gEl.getAttribute('data-assetid'))
          if (assetIds.indexOf(lineAssetId) > -1) {
            // 还原线路颜色
            gEl.children[0].setAttribute('stroke', this.assetColors.get(gEl.getAttribute('data-assetid')))
          } else {
            gEl.children[0].setAttribute('stroke', assetHideColor)
          }
        }
      }

      // 站点
      let pEl
      let stationAssetId
      let flag
      for (let i = 0; i < this.pathNodeList.length; i++) {
        pEl = this.pathNodeList[i]
        if (pEl.hasAttribute('data-assetid')) {
          stationAssetId = pEl.getAttribute('data-assetid')
          flag = false
          if (stationAssetId.split(',').length > 1) {
            // 如果是换乘站, 验证是否包含在显示线路目录中
            stationAssetId.split(',').forEach(lineId => {
              if (assetIds.indexOf(parseInt(lineId)) > -1) {
                flag = true
              }
            })
          }

          if (flag || assetIds.indexOf(parseInt(stationAssetId)) > -1) {
            // 还原站点颜色
            this.fillStationColor(pEl, true)
          } else {
            this.fillStationColor(pEl, false)
          }
        }
      }
    },
    fillStationColor (pEl, isShow) {
      let stationFill = this.themeIsDark ? '#444' : '#E6E6E6'
      let stationStroke = this.themeIsDark ? '#444' : '#E6E6E6'
      let stationText = this.themeIsDark ? '#444' : '#E6E6E6'

      if (isShow) {
        stationStroke = this.assetColors.get(pEl.getAttribute('data-assetid'))
        stationFill = '#ffffff'
        stationText = this.themeIsDark ? '#ffffff' : '#000'
      }

      if (pEl.getAttribute('name') === 'standardText') { // 常规站点
        pEl.nextSibling.nextElementSibling.children[0].setAttribute('fill', stationText)
      } else if (pEl.getAttribute('name') === 'p_r_station') { // 换乘站
        pEl.setAttribute('display', isShow ? '' : 'none')
      } else { // 站点圆圈
        pEl.setAttribute('stroke', stationStroke)
        pEl.setAttribute('fill', stationFill)
      }
    },
    handleMapGetCenterByLine (lineId) {
      // 获取线路的全部站点
      const stationList = []
      let pEl

      for (let i = 0; i < this.pathNodeList.length; i++) {
        pEl = this.pathNodeList[i]
        if (pEl.hasAttribute('data-stationid') && (pEl.getAttribute('name') === 'p_station' || pEl.getAttribute('name') === 'p_r_station')) {
          if (pEl.hasAttribute('data-assetid') && pEl.getAttribute('data-assetid').split(',').indexOf(lineId) > -1) {
            const centerPointStr = pEl.parentNode.getAttribute('transform').substr(10).split(')')[0].split(' ')
            stationList.push({
              id: pEl.getAttribute('data-stationid'),
              x: parseInt(centerPointStr[0]),
              y: parseInt(centerPointStr[1])
            })
          }
        }
      }

      // 计算出实际跳转坐标
      // const stationSortList = stationList.sort((a, b) => { return b.id > a.id })
      // const centerPoint = stationSortList[parseInt(stationList.length / 2)]
      const centerPoint = stationList[parseInt(stationList.length / 2)]
      if (centerPoint) {
        const svgSizes = this.currentMapObj.getSizes()
        const newPoint = {
          x: -(centerPoint.x * svgSizes.realZoom) + (svgSizes.width / 2),
          y: -(centerPoint.y * svgSizes.realZoom) + (svgSizes.height / 2)
        }

        this.handleMapAnimation(newPoint)
      }
    },
    handleMapGetCenterByStation (stationId) {
      let centerPoint
      let pEl

      for (let i = 0; i < this.pathNodeList.length; i++) {
        pEl = this.pathNodeList[i]
        if (pEl.hasAttribute('data-stationid') && (pEl.getAttribute('name') === 'p_station' || pEl.getAttribute('name') === 'p_r_station') &&
          checkStationidInList(pEl.getAttribute('data-stationid'), stationId)) {
          const centerPointStr = pEl.parentNode.getAttribute('transform').substr(10).split(')')[0].split(' ')
          centerPoint = {
            id: pEl.getAttribute('data-stationid'),
            x: parseInt(centerPointStr[0]),
            y: parseInt(centerPointStr[1])
          }
          // 退出循环
          break
        }
      }

      // 计算出实际跳转坐标
      const svgSizes = this.currentMapObj.getSizes()
      if (centerPoint) { // 处理关闭窗口后的空值
        const newPoint = {
          x: -(centerPoint.x * svgSizes.realZoom) + (svgSizes.width / 2),
          y: -(centerPoint.y * svgSizes.realZoom) + (svgSizes.height / 2)
        }

        this.handleMapAnimation(newPoint)
      }
    },
    handleMapAnimation (targetPoint) {
      const _this = this
      const currentPan = this.currentMapObj.getPan()

      var animationTime = 300 // ms
      var animationStepTime = 30 // one frame per 30 ms
      var animationSteps = animationTime / animationStepTime
      var animationStep = 0
      var intervalID = null
      var stepX = (currentPan.x - targetPoint.x) / animationSteps
      var stepY = (currentPan.y - targetPoint.y) / animationSteps

      intervalID = setInterval(function () {
        if (animationStep++ < animationSteps) {
          _this.currentMapObj.pan({ x: currentPan.x - stepX * animationStep, y: currentPan.y - stepY * animationStep })
        } else {
          // 取消无限循环
          clearInterval(intervalID)
        }
      }, animationStepTime)
    }
  },
  computed: {
    themeIsDark () {
      // 获取并设置当前页面的主题
      const theme = getStorage('oohforce-workbench-theme') || 'dark'
      return theme === 'dark'
      // return true
    }
  },
  watch: {
    currentCategory (newVal, oldVal) {
      if ((newVal === 15 && oldVal !== 15) || (newVal !== 15 && oldVal === 15)) {
        this.initMap()
      }
    }
  }
}
</script>
