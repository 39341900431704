<template>
    <div>
      <Row class="p-t-10 p-b-5">
          <i-col span="12"><h3>作业金额</h3></i-col>
          <i-col span="12" class="remark text-right">单元：元</i-col>
      </Row>
      <Row class="table-title p-t-2">
          <i-col span="6">类型</i-col>
          <i-col span="6">已发生</i-col>
          <i-col span="6">已对账</i-col>
          <i-col span="6">已结算</i-col>
      </Row>
      <Row v-for="(amountItem,index) in workAmountData" :key="index" class="p-t-2" :class="(index%2 === 0)?'table-row-1':'table-row-2'">
          <i-col span="6" class="p-l-5">{{amountItem.name}}</i-col>
          <i-col span="6">{{formatMoney(amountItem.targetAmount)}}</i-col>
          <i-col span="6">{{formatMoney(amountItem.confirmedAmount)}}</i-col>
          <i-col span="6">{{formatMoney(amountItem.settledAmount)}}</i-col>
      </Row>

      <Row class="p-t-20 p-b-5">
          <i-col span="24"><h3>作业分布</h3></i-col>
      </Row>
      <Row class="table-title p-t-2">
          <i-col span="10" class="p-l-5">线路</i-col>
          <i-col span="8">站点</i-col>
          <i-col span="6">数量</i-col>
      </Row>
      <Row v-for="(stationItem,index) in workStationData" :key="'s'+index" class="p-t-2" :class="(index%2 === 0)?'table-row-1':'table-row-2'">
          <i-col span="10" class="p-l-5">{{stationItem.assetName}}</i-col>
          <i-col span="8">{{stationItem.stationName}}</i-col>
          <i-col span="6">{{stationItem.workNum}}</i-col>
      </Row>
      <div class="paging_style">
          <Page size="small" :total="workStationTotal" :page-size="stationQuery.pageSize" :current="stationQuery.pageNumber" show-total @on-change="changePage"></Page>
      </div>
    </div>
</template>

<script>
import { listWorkItemAmountGroupByType, listWorkItemGroupByStation } from '@/api/dw/maintainRole'
import { toNumber } from '@/utils/wnumb_own'

export default {
  data () {
    return ({
      stationQuery: {
        pageSize: 15,
        pageNum: 1
      },
      workAmountData: [], // 作业金额数据
      workStationData: [], // 作业站点数据
      workStationTotal: 0
    })
  },
  methods: {
    initPageData (searchModel) {
      listWorkItemAmountGroupByType(searchModel).then(res => {
        this.workAmountData = res
      })

      this.stationQuery.pageNum = 1
      this.stationQuery = Object.assign(this.stationQuery, searchModel)
      this.loadWorkStaionData()
    },
    loadWorkStaionData () {
      listWorkItemGroupByStation(this.stationQuery).then(res => {
        this.workStationTotal = res.totalRow
        this.workStationData = res.list
      })
    },
    changePage (currentPage) {
      this.stationQuery.pageNum = currentPage
      this.loadWorkStaionData()
    },
    formatMoney (number) {
      return toNumber(number)
    }
  }
}
</script>
