<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent" @updatePageData="updatePageData"></component>
      </div>
      <div slot="right_box_content">
        <rightComponent ref="right"></rightComponent>
      </div>
      <div slot="bottom_box_content">
        <bottomComponent ref="bottom"></bottomComponent>
      </div>
      <div slot="bg_map">
        <centerComponent ref="center"></centerComponent>
      </div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
import bottomComponent from './components/Bottom'
import rightComponent from './components/Right'
import centerComponent from './components/Center'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    bottomComponent,
    centerComponent,
    rightComponent,
    leftComponent: () => import('./components/Left')
  },
  mounted () {
    this.setShowLeftBlock(true)
    this.setShowRightBlock(true)
    this.setLeftComponent('leftComponent')
    this.setBottomHeight('300px')
    this.setMapComponent('centerComponent')
  },
  methods: {
    updatePageData (searchModel) {
      if (this.$refs.right) {
        this.$refs.right.initPageData(searchModel)
        this.$refs.bottom.initPageData(searchModel)
        this.$refs.center.initPageData(searchModel)
      } else {
        setTimeout(() => {
          this.updatePageData(searchModel)
        }, 200)
      }
    }
  },
  destroyed () {
    this.setShowLeftBlock(true)
    this.setShowMap(true)
    this.setShowRightBlock(true)
    this.setBottomHeight('300px')
    this.setLeftComponent('')
    this.setRightComponent('')
    this.setBottomComponent('')
    this.setMapComponent('')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
