import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader.js'

import { getDeviceListByProduct } from '@/api/product/device'
var TWEEN = require('@tweenjs/tween.js')

export const threeOperateMixin = {
  data () {
    return {
      threeId: '',
      allowLightboxs: [], // 可用灯箱清单
      deviceArray: [], // 可用灯箱设备详情
      lightboxsLookatArray: [], // 可用灯箱的相机参数

      mesh: null,
      camera: null,
      scene: null,
      renderer: null,
      controls: null,

      ambientLight: null,
      directionalLight: null,

      scaleMesh: 0, // 默认模型设置缩放倍数
      centerPosition: null // 场景中心点坐标
    }
  },
  created () {
    let guid = ''
    for (var i = 1; i <= 32; i++) {
      var n = Math.floor(Math.random() * 16.0).toString(16)
      guid += n
    }
    this.threeId = guid
  },
  methods: {
    beginLoad3dMesh () {
      // 清空已存储的3D场景对象
      if (this.renderer) {
        this.renderer.clear()
        document.getElementById('3dcontainer_' + this.threeId).innerHTML = ''
        this.renderer.dispose()
      }

      let xmlstr = this.array3dMeshs[this.chooseMeshIndex].svg
      xmlstr = xmlstr.replaceAll('C:\\Users\\CR_Zhao\\Pictures\\pixiv56697596.jpg', 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlkAAAFaCAIA')
      xmlstr = xmlstr.replaceAll('C:\\Users\\CR_Zhao\\Pictures\\timg1.jpg', 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlkAAAFaCAIA')
      xmlstr = xmlstr.replaceAll('C:/Users/CR_Zhao/Pictures/timg1.jpg', 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAlkAAAFaCAIA')

      this.$nextTick(() => {
        this.init3D(xmlstr)
        this.$emit('three-finished')
      })
    },
    init3D (xml) {
      this.createScene() // 创建场景
      this.loadCollada(xml) // 加载Collada模型
      this.createLight() // 创建光源
      this.createCamera() // 创建相机
      this.createHelper() // 创建网格线
      this.createRender() // 创建渲染器
      this.createControls() // 创建控件对象
      this.render() // 渲染
    },
    // 创建场景
    createScene () {
      this.scene = new THREE.Scene()
    },
    // 加载Collada模型
    loadCollada (xml) {
      // 转成base64
      var url = 'data:application/xml,' + encodeURIComponent(xml)

      const loader = new ColladaLoader()
      loader.load(url, LoadResult => {
        // 获取所有的灯箱，自定义相机参数
        const libraryNodes = Object.values(LoadResult.library.nodes)
        this.lightboxsLookatArray = []
        libraryNodes.forEach(element => {
          if (element.name.substr(0, 1) === 'C') {
            this.lightboxsLookatArray.push({
              name: element.name,
              lookat: element.lookat[0]
            })
          }
        })

        this.mesh = LoadResult.scene
        // // 动态设置模型放大比例
        this.computeScale2(this.mesh)
        this.initModelColor()
        this.scene.add(this.mesh)
      })
    },
    computeScale2 (obj) {
      // 包围盒全自动计算：模型整体居中、模型全屏显示
      var box3 = new THREE.Box3()
      box3.expandByObject(obj) // 计算层级模型包围盒
      var v3 = new THREE.Vector3()
      // 获得包围盒长宽高尺寸，结果保存在参数三维向量对象v3中
      box3.getSize(v3)

      // 获取模型的最大值
      var sideLength = (v3.x > v3.y) ? v3.x : v3.y
      if (sideLength < v3.z) {
        sideLength = v3.z
      }

      // 根据最大边长设置缩放倍数，尽量全屏显示
      // 150：参照相机正投影相机参数left、right、top和bottom设置一个合适的值
      // 过大会超出屏幕范围，过小全屏效果不明显
      var S = 400 / sideLength
      // 对模型进行缩放操作，实现全屏效果
      obj.scale.set(S, S, S)
      this.scaleMesh = S

      // 重新计算包围盒，重新计算包围盒，不能使用原来的包围盒必须重新声明一个包围盒
      var newBox3 = new THREE.Box3()
      newBox3.expandByObject(obj)
      var center = new THREE.Vector3() // 计算一个层级模型对应包围盒的几何体中心
      newBox3.getCenter(center)
      // 重新设置模型的位置，使模型居中
      obj.position.x = obj.position.x - center.x
      obj.position.y = obj.position.y - center.y
      obj.position.z = obj.position.z - center.z
      // 复制中间点位置，已备后续自定定位模型
      this.centerPosition = center
    },
    initModelColor () {
      this.allowLightboxs = []

      for (let index = 0; index < this.mesh.children.length; index++) {
        const element = this.mesh.children[index]
        // 查询出所有可用的灯箱清单
        if (element.name.substr(0, 1) === 'M') {
          this.allowLightboxs.push(element)
        }

        // 将底部的平面设置为灰色
        if (element.name.indexOf('floor') > -1) {
          element.material.color = new THREE.Color(0xa9a9a9)
        }

        // 开启建筑物的透视关系
        if (element.name.indexOf('column') > -1) {
          element.children.forEach(item => {
            item.material.transparent = true
            item.material.side = THREE.DoubleSide
          })
        }

        // 将灯箱贴图替换为默认图片
        if (element.material) {
          for (let index = 0; index < element.material.length; index++) {
            if (element.material[index].map === null) {
              // 将灯箱背板全部设置为灰色
              element.material[index].color.setHex(0x4f4f4f)
            }
          }
        }
      }
      // 获取灯箱名称详情
      this.formatDeviceInfo()
    },
    // 创建光源
    createLight () {
      // 环境光
      this.ambientLight = new THREE.AmbientLight(0xffffff, 1) // 创建环境光
      this.scene.add(this.ambientLight) // 将环境光添加到场景

      // 平行光 White directional light at half intensity shining from the top.
      this.directionalLight = new THREE.DirectionalLight(0xffffff, 0.1)
      this.directionalLight.castShadow = true
      this.scene.add(this.directionalLight)
    },
    // 创建相机
    createCamera () {
      const element = document.getElementById('3dcontainer_' + this.threeId)
      const width = element.clientWidth // 窗口宽度
      const height = element.clientHeight // 窗口高度
      const k = width / height // 窗口宽高比
      // var s = 100 // 三维场景显示范围控制系数，系数越大，显示的范围越大
      this.camera = new THREE.PerspectiveCamera(40, k, 1, 1000)
      this.camera.position.set(250, 250, 150) // 设置相机位置
      this.camera.lookAt(new THREE.Vector3(15, 15, 15)) // 设置相机方向
      // 对于正投影相机，鼠标中键滚动的时候，鼠标中键会改变相机的zoom属性
      // this.camera.zoom = 2
      // this.camera.updateProjectionMatrix() // 注意更新相机对象的投影矩阵

      this.scene.add(this.camera)
    },
    // 创建网格线
    createHelper () {
      const helper = new THREE.GridHelper(2000, 200)
      helper.position.y = -5
      helper.material.opacity = 0.25
      helper.material.transparent = true
      this.scene.add(helper)
    },
    // 创建渲染器
    createRender () {
      const element = document.getElementById('3dcontainer_' + this.threeId)
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: false })
      this.renderer.setSize(element.clientWidth, element.clientHeight) // 设置渲染区域尺寸
      this.renderer.shadowMap.enabled = true // 显示阴影
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
      this.renderer.physicallyCorrectLights = false // 正常光照
      this.renderer.setClearColor(0xDCDCDC, 1) // 设置背景颜色
      element.appendChild(this.renderer.domElement)
    },
    // 创建控件对象
    createControls () {
      this.controls = new OrbitControls(this.camera, this.renderer.domElement)
      // 设置缩放比例区间
      this.controls.maxDistance = 1000 // 最远放大比例
      this.controls.minDistance = 2
      this.controls.zoomSpeed = 3

      // 是否开启右键拖拽
      this.controls.enablePan = true
    },
    render () {
      this.renderer.render(this.scene, this.camera)
      this.controls.update()
      requestAnimationFrame(this.render)

      TWEEN.update()
    },
    // 格式化可用灯箱名称
    formatDeviceInfo () {
      const deviceIds = []
      this.allowLightboxs.forEach(element => {
        deviceIds.push(element.name.substr(1))
      })

      if (deviceIds.length > 0) {
        getDeviceListByProduct({ deviceIdList: deviceIds.join(',') }).then(res => {
          this.deviceArray = res
        })
      }
    },
    autoFocusLightbox (lightboxLookat) {
      // 相机自动对焦
      const tx = this.centerPosition.x
      const ty = this.centerPosition.y
      const tz = this.centerPosition.z

      var oldP = {
        x: this.camera.position.x,
        y: this.camera.position.y,
        z: this.camera.position.z
      }
      var oldT = {
        x: this.controls.target.x,
        y: this.controls.target.y,
        z: this.controls.target.z
      }
      // 初始化位置
      var newP = {
        x: this.scaleMesh * parseFloat(lightboxLookat[0]) - tx,
        y: this.scaleMesh * parseFloat(lightboxLookat[2]) - ty,
        z: -1 * this.scaleMesh * parseFloat(lightboxLookat[1]) - tz
      }
      var newT = {
        x: this.scaleMesh * parseFloat(lightboxLookat[3]) - tx,
        y: this.scaleMesh * parseFloat(lightboxLookat[5]) - ty,
        z: -1 * this.scaleMesh * parseFloat(lightboxLookat[4]) - tz
      }

      this.animateCamera(oldP, oldT, newP, newT, () => {
        // 重置控制器的轨道焦点， 不然会始终为(0,0,0)
        this.controls.target = new THREE.Vector3(this.scaleMesh * parseFloat(lightboxLookat[3]) - tx, this.scaleMesh * parseFloat(lightboxLookat[5]) - ty, -1 * this.scaleMesh * parseFloat(lightboxLookat[4]) - tz)
      })
    },
    /**
     * @oldP  相机原来的位置
     * @oldT  target原来的位置
     * @newP  相机新的位置
     * @newT  target新的位置
     * @callBack  动画结束时的回调函数
     */
    animateCamera (oldP, oldT, newP, newT, callBack) {
      var _this = this
      var tween = new TWEEN.Tween({
        x1: oldP.x, // 相机x
        y1: oldP.y, // 相机y
        z1: oldP.z, // 相机z
        x2: oldT.x, // 控制点的中心点x
        y2: oldT.y, // 控制点的中心点y
        z2: oldT.z // 控制点的中心点z
      })
      tween.to({
        x1: newP.x,
        y1: newP.y,
        z1: newP.z,
        x2: newT.x,
        y2: newT.y,
        z2: newT.z
      }, 2000)
      tween.onUpdate(function (object) {
        _this.camera.position.x = object.x1
        _this.camera.position.y = object.y1
        _this.camera.position.z = object.z1
        _this.controls.target.x = object.x2
        _this.controls.target.y = object.y2
        _this.controls.target.z = object.z2
        _this.controls.update()
      })
      tween.onComplete(function () {
        _this.controls.enabled = true
        callBack && callBack()
      })
      tween.easing(TWEEN.Easing.Cubic.InOut)
      tween.start()
    }
  }
}
