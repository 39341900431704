<template>
  <Modal v-model="modalGraph3D" :title="modalGraph3DTitle" width='1400' :styles="{top: '50px'}" footer-hide>
    <div slot="close">
      <Button type="success" size="small" style="margin:5px 40px 0 0;" @click="handleChangeMode">切换SVG模式</Button>
      <a class="ivu-modal-close" style="margin: -10px -10px 0 0;"><i class="ivu-icon ivu-icon-ios-close"></i></a>
    </div>

    <Row class="p-b-10">
      <i-col span="2">站点场景：</i-col>
      <i-col span="22">
        <RadioGroup v-model="chooseMeshIndex" size="small" type="button" button-style="solid" @on-change="hanldeChangeMesh">
            <Radio v-for="(item,index) in array3dMeshs" :key="index" :label="index">{{item.positionName + ' ' + item.floor + '层'}} {{item.code}}</Radio>
        </RadioGroup>
      </i-col>
    </Row>
    <Row class="p-b-10">
      <i-col span="2" class="">站点内灯箱媒体：</i-col>
      <i-col span="22">
        <RadioGroup v-model="chooseLightbox" size="small" type="button" button-style="solid" @on-change="handleSelectLightbox">
            <Radio v-for="item in deviceArray" :key="item.deviceId" :label="item.deviceId">{{item.devicemodelName}} {{item.code}}</Radio>
        </RadioGroup>
      </i-col>
    </Row>
    <Row>
      <i-col span="2">显示建筑物：</i-col>
      <i-col span="6">
        <CheckboxGroup v-model="chooseBuilds" @on-change="changePerspective">
            <Checkbox :label="1">站厅柱子</Checkbox>
        </CheckboxGroup>
      </i-col>

      <i-col span="2">显示方式：</i-col>
      <i-col span="6">
        <Checkbox v-model="choosePerspective" @on-change="changePerspective">开启建筑物透视</Checkbox>
        <Checkbox v-model="showHelper" @on-change="handleChangeShowHelper">显示模型网格</Checkbox>
      </i-col>
    </Row>
    <p class="remark p-b-5 text-right">* 您可以按住鼠标右键，拖动建筑模型</p>
    <div :id="'3dcontainer_'+threeId" style="width: 100%;height: 700px;"></div>
  </Modal>
</template>

<script>
import * as THREE from 'three'
import { threeOperateMixin } from '@/assets/mixins/three-operate'
import { getStation3dByStationId } from '@/api/product/stationgraph'

export default {
  mixins: [threeOperateMixin],
  data () {
    return {
      stationIds: null,
      modalGraph3D: false,
      modalGraph3DTitle: '',
      array3dMeshs: [], // 全部可用的3D场景
      chooseMeshIndex: 0, // 选中的3D场景

      chooseLightbox: null, // 选中的灯箱
      chooseBuilds: [1, 2], // 显示建筑物集合
      choosePerspective: false, // 是否开启透视关系
      showHelper: true, // 显示网格底纹

      mesh: null,
      camera: null,
      scene: null,
      renderer: null,
      controls: null
    }
  },
  methods: {
    initData (stationId) {
      this.stationIds = stationId
      // 清空已存储的3D场景对象
      if (this.renderer) {
        this.renderer.clear()
        document.getElementById('3dcontainer_' + this.threeId).innerHTML = ''
        this.renderer.dispose()
      }
      this.modalGraph3DTitle = ''
      this.allowLightboxs = []
      this.deviceArray = []

      this.modalGraph3D = true

      getStation3dByStationId({ stationIds: stationId }).then(res => {
        this.array3dMeshs = res
        this.chooseMeshIndex = 0

        if (res.length > 0) {
          this.modalGraph3DTitle = res[0].assetName + ' - ' + res[0].stationName
          this.beginLoad3dMesh()
        }
      })
    },
    handleChangeMode () {
      this.$emit('handleChangeSvgMode', this.stationIds)
    },
    hanldeChangeMesh () {
      this.chooseLightbox = null
      this.beginLoad3dMesh()
    },
    changePerspective () { // 变更透视关系
      for (let index = 0; index < this.mesh.children.length; index++) {
        const element = this.mesh.children[index]
        if (element.name.indexOf('column') > -1) {
          element.visible = this.chooseBuilds.includes(1)
          // 设置透明度
          if (element.children.length > 0) {
            element.children.forEach(item => {
              item.material.opacity = this.choosePerspective ? 0.2 : 1
            })
          }
        }
      }
    },
    handleChangeShowHelper () { // 显示网格模型
      this.scene.children.forEach(element => {
        if (element instanceof THREE.GridHelper) {
          element.visible = this.showHelper
        }
      })
    },
    tagResource (resourceList) {
      const tagResourceIds = resourceList.map(x => 'M' + x.deviceId)

      this.allowLightboxs.forEach(item => {
        for (let index = 0; index < item.material.length; index++) {
          const element = item.material[index]
          if (element.map !== null) { // 获取灯箱边框和主体
            continue
          }

          if (tagResourceIds.includes(item.name)) {
            var newColor = element.color.clone()
            newColor.setHex(0xed4014)

            var newMat = element.clone()
            newMat.color.set(newColor)
            item.material[index] = newMat
          } else if (element.color.getHex() === 0xed4014) {
            element.color.setHex(0x4f4f4f)
          }
        }
      })
    },
    tagResourceImg (resourceImgs) {
      const tagResourceIds = resourceImgs.map(x => 'M' + x.deviceId)

      this.allowLightboxs.forEach(item => {
        for (let index = 0; index < item.material.length; index++) {
          const element = item.material[index]
          if (element.map === null) { // 获取灯箱画面部分
            continue
          }

          if (tagResourceIds.includes(item.name)) {
            var imgUrl2 = resourceImgs.find(x => ('M' + x.deviceId) === item.name).imgUrl
            imgUrl2 += '?x-oss-process=image/resize,w_768'
            imgUrl2 = imgUrl2.replace('http://', 'https://')

            new THREE.TextureLoader().load(imgUrl2, function (texture) {
            // 以便在下次使用纹理时触发一次更新
              texture.needsUpdate = true
              texture.anisotropy = 5 // 显示精细度， 默认为1， 值越大越消耗性能
              element.map = texture
            })
          }
        }
      })
    },
    handleSelectLightbox () {
      const lightboxLookat = this.lightboxsLookatArray.find(x => x.name === 'C' + this.chooseLightbox).lookat

      this.autoFocusLightbox(lightboxLookat)
    }
  }
}
</script>
